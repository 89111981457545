body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
  }
  
  .gallery-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-auto-rows: minmax(200px, 1fr);
    gap: 10px;
    padding: 10px;
    width: 100vw;
    height: calc(100vh - 100px); /* 100vh menos o espaço ocupado pelo Header */
    background-color: #000;
    box-sizing: border-box;
  }
  
  .gallery-item {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .gallery-item:hover .gallery-image {
    transform: scale(1.1);
  }
  
  .gallery-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    padding: 20px;
    text-align: center;
  }
  
  .gallery-item:hover .gallery-overlay {
    opacity: 1;
  }
  
  .gallery-overlay h3 {
    margin-bottom: 10px;
    font-size: 1.5rem;
    color: #00FF00; /* Cor verde para o título */
  }
  
  .gallery-overlay p {
    font-size: 1rem;
    color: #ccc; /* Cor cinza claro para a descrição */
  }
  

  .fullscreen-modal .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .fullscreen-modal .modal-content {
    width: 100vw;
    height: 100vh;
    border: none;
    border-radius: 0;
  }
  
  .fullscreen-modal .modal-body {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .fullscreen-modal img {
    max-width: 100%;
    max-height: 100%;
  }

  .fullscreen-modal .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .fullscreen-modal .modal-content {
    width: 100vw;
    height: 100vh;
    border: none;
    border-radius: 0;
  }
  
  .fullscreen-modal .modal-body {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
  }
  
  .video-container {
    position: relative;
    width: 100%;
    max-width: 800px;
    height: auto;
  }
  
  .play-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 20px;
  }
  
  .play-overlay:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  video {
    width: 100%;
    height: auto;
  }
  
  